/*
 ********************************************************************************************************************
 * -*- coding:utf-8 -*-                                                                                             *
 * Part of OCPickingManager. See LICENSE file for full copyright and licensing details                              *
 *                                                                                                                  *
 * Copyright (C) 2018-2019 Overtake (http://www.overtakenet.it) - Soluzioni digitali - All Rights Reserved          *
 * Unauthorized copying of this file, via any medium is strictly prohibited                                         *
 *                                                                                                                  *
 * The Content of this file is proprietary and confidential, you can use or modify it only if there was a contract  *
 * with Overtake and You. If you need more information contact: info@overtakenet.it                                 *
 *                                                                                                                  *
 *                                                        Written by Mattia Ferrari <mattia.ferrari@overtakenet.it> *
 *                                                                                                    2021 Febbrary *
 ********************************************************************************************************************
 */

import { Injectable } from "@angular/core";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExportToExcel {
  constructor() {

  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
