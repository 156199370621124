/*
 ********************************************************************************************************************
 * -*- coding:utf-8 -*-                                                                                             *
 * Part of OVPickingManager. See LICENSE file for full copyright and licensing details                              *
 *                                                                                                                  *
 * Copyright (C) 2018-2019 Overtake (http://www.overtakenet.it) - Soluzioni digitali - All Rights Reserved          *
 * Unauthorized copying of this file, via any medium is strictly prohibited                                         *
 *                                                                                                                  *
 * The Content of this file is proprietary and confidential, you can use or modify it only if there was a contract  *
 * with Overtake and You. If you need more information contact: info@overtakenet.it                                 *
 *                                                                                                                  *
 *                                                        Written by Mattia Ferrari <mattia.ferrari@overtakenet.it> *
 *                                                                                                    2021 Febbrary *
 ********************************************************************************************************************
 */

export class Connection {
  public static url = "https://wszippo.overtakenet.it";
  public static port = 443;
}
