/*
 ********************************************************************************************************************
 * -*- coding:utf-8 -*-                                                                                             *
 * Part of OVPickingManager. See LICENSE file for full copyright and licensing details                              *
 *                                                                                                                  *
 * Copyright (C) 2018-2019 Overtake (http://www.overtakenet.it) - Soluzioni digitali - All Rights Reserved          *
 * Unauthorized copying of this file, via any medium is strictly prohibited                                         *
 *                                                                                                                  *
 * The Content of this file is proprietary and confidential, you can use or modify it only if there was a contract  *
 * with Overtake and You. If you need more information contact: info@overtakenet.it                                 *
 *                                                                                                                  *
 *                                                        Written by Mattia Ferrari <mattia.ferrari@overtakenet.it> *
 *                                                                                                    2021 Febbrary *
 ********************************************************************************************************************
 */

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Response } from "../../model/data/response/Response.model";
import { Order } from "../../model/data/Order.model";
import { OrderPack } from "../../model/data/OrderPack.model";
import { HttpClient } from "@angular/common/http";
import { Connection } from "../../data/connection/connection";
import { Authentication } from "./authentication.service";
import { Page } from '../../model/data/response/Page.model';

const BASEPATH = "web/orders"

@Injectable()
export class OrdersApi {
  constructor(
    private _httpService: HttpClient,
    private _authService: Authentication, 
    private _router: Router) {
  }

  //Visualizza gli ordini caricati nel sistema
  listNewWorking(page: Page, order?: { dir: string, prop: string }[], query?: Map<any, any>): Observable<Response<Array<Order>>> {
    if (this._authService.isLoggedIn) {
      let url = Connection.url + ":" + Connection.port + "/" + BASEPATH
        + "/listNewWorking"
        + "?sessionCode=" + this._authService.getSession()
        + "&limit=" + ((page.pageNumber + 1) * page.size)
        + "&offset=" + page.pageNumber * page.size;
      if (order) {
        url += "&sort_by=" + order[0].prop;
        url += "&order_by=" + order[0].dir;
      }
      if (query)
        url += "&q=" + JSON.stringify(query);
      return this._httpService.get<Response<Array<Order>>>(url);
    } else {
      this._router.navigateByUrl('/auth/login');
      return null;
    }
  }

  listDone(page: Page, order?: { dir: string, prop: string }[], query?: Map<any, any>): Observable<Response<Array<Order>>> {
    if (this._authService.isLoggedIn) {
      let url = Connection.url + ":" + Connection.port + "/" + BASEPATH
        + "/listDone"
        + "?sessionCode=" + this._authService.getSession()
        + "&limit=" + ((page.pageNumber + 1) * page.size)
        + "&offset=" + page.pageNumber * page.size;
      if (order) {
        url += "&sort_by=" + order[0].prop;
        url += "&order_by=" + order[0].dir;
      }
      if (query)
        url += "&q=" + JSON.stringify(query);
      return this._httpService.get<Response<Array<Order>>>(url);
    } else {
      this._router.navigateByUrl('/auth/login');
      return null;
    }
  }

  packList(orderID: number): Observable<Response<Array<OrderPack>>> {
    if (this._authService.isLoggedIn) {
      let url = Connection.url + ":" + Connection.port + "/" + BASEPATH
        + "/packList"
        + "?sessionCode=" + this._authService.getSession()
        + "&orderID=" + orderID;
      return this._httpService.get<Response<Array<OrderPack>>>(url);
    } else {
      this._router.navigateByUrl('/auth/login');
      return null;
    }
  }

  setToNew(orderID: number): Observable<Response<number>> {
    if (this._authService.isLoggedIn) {
      let url = Connection.url + ":" + Connection.port + "/" + BASEPATH
        + "/setToNew";
      let object = {
        sessionCode: this._authService.getSession(),
        orderID: orderID
      }
      return this._httpService.post<Response<number>>(url, object);
    } else {
      this._router.navigateByUrl('/auth/login');
      return null;
    }
  }

  disable(orderID: number): Observable<Response<number>> {
    if (this._authService.isLoggedIn) {
      let url = Connection.url + ":" + Connection.port + "/" + BASEPATH
        + "/disable";
      let object = {
        sessionCode: this._authService.getSession(),
        orderID: orderID
      }
      return this._httpService.post<Response<number>>(url, object);
    } else {
      this._router.navigateByUrl('/auth/login');
      return null;
    }
  }

  updatePriority(orderID: number, value: number): Observable<Response<number>> {
    if (this._authService.isLoggedIn) {
      let url = Connection.url + ":" + Connection.port + "/" + BASEPATH
        + "/updatePriority";
      let object = {
        sessionCode: this._authService.getSession(),
        orderID: orderID,
        value: value
      }
      return this._httpService.post<Response<number>>(url, object);
    } else {
      this._router.navigateByUrl('/auth/login');
      return null;
    }
  }
}
