/*
 ********************************************************************************************************************
 * -*- coding:utf-8 -*-                                                                                             *
 * Part of OVPickingManager. See LICENSE file for full copyright and licensing details                              *
 *                                                                                                                  *
 * Copyright (C) 2018-2019 Overtake (http://www.overtakenet.it) - Soluzioni digitali - All Rights Reserved          *
 * Unauthorized copying of this file, via any medium is strictly prohibited                                         *
 *                                                                                                                  *
 * The Content of this file is proprietary and confidential, you can use or modify it only if there was a contract  *
 * with Overtake and You. If you need more information contact: info@overtakenet.it                                 *
 *                                                                                                                  *
 *                                                        Written by Mattia Ferrari <mattia.ferrari@overtakenet.it> *
 *                                                                                                    2021 Febbrary *
 ********************************************************************************************************************
 */

import { Injectable, OnInit, NgZone } from '@angular/core';
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { Connection } from "../../data/connection/connection";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { Response } from "../../model/data/response/Response.model";
import { CookieService } from 'ngx-cookie-service';
import { User } from '../../model/data/User.model';

const BASEPATH = "web/users"

@Injectable({
  providedIn: 'root'
})
export class Authentication implements OnInit{
  private sessionCode: string = "";
  public isLoggedIn: boolean = false;
  public showLoader: boolean = false;

  constructor(
    private _httpService: HttpClient,
    public _ngZone: NgZone,
    public _toaster: ToastrService,
    private _cookieService: CookieService,
    private _router: Router) {
  }

  ngOnInit(): void {
  }

  //login user function
  login(username: string, password: string) {
    let url = Connection.url + ":" + Connection.port + "/" + BASEPATH
      + "/login";
    this._httpService.post<Response<string>>(url, {
      username: username,
      password: password
    }).subscribe((response) => {
      this.sessionCode = response.data;
      if (this.sessionCode != "") {
        this.showLoader = false;
        this.isLoggedIn = true;
        this._cookieService.set('sessionCode', this.sessionCode);
        this._ngZone.run(() => {
          this._router.navigate(['']);
        });
      } else {
        this.isLoggedIn = false;
        this.showLoader = false;
        localStorage.clear();
        this._toaster.error('Username o password errati.');
      }
    }, (error) => {
        this.isLoggedIn = false;
        this.showLoader = false;
        localStorage.clear();
        this._toaster.error('Username o password errati.');
    });
  }

  logout() {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.showLoader = false;
    localStorage.clear();
    this._cookieService.deleteAll('sessionCode', '/auth/login');
    this._router.navigate(['/auth/login']);
  }

  //getSessionCode
  getSession(): string {
    return this.sessionCode;
  }
}
