import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'orders',
    loadChildren: () => import('../../pages/orders-pages/orders-pages.module').then(m => m.OrderPagesModule),
    data: {
      breadcrumb: "Orders"
    }
  }
];
