import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { User } from '../../shared/model/data/User.model';
import { Authentication } from '../../shared/services/api/authentication.service';

type UserFields = 'username' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    'username': '',
    'password': '',
  };
  public errorMessage: any;

  constructor(public _authentication: Authentication,
    private fb: FormBuilder,
    private _router: Router) {
    this.loginForm = fb.group({
      username: ['username', [Validators.required]],
      password: ['password', Validators.required]
    });
  }

  ngOnInit() {
    if (this._authentication.isLoggedIn) {
      this._router.navigate(['']);
    }
  }

  // Simple Login
  login() {
    this._authentication.login(this.loginForm.value['username'], this.loginForm.value['password']);
  }

}
