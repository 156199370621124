// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDvQnoX3YBqxSK_0jkAW0J_nftj_syFuB4",
    authDomain: "ovpickingmanager.firebaseapp.com",
    databaseURL: "https://ovpickingmanager.firebaseio.com",
    projectId: "ovpickingmanager",
    storageBucket: "ovpickingmanager.appspot.com",
    messagingSenderId: "75889458612",
    appId: "1:75889458612:web:bf9818318e6b395d6946e1",
    measurementId: "G-GVJMXMTXQY"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
